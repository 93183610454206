:root{
  --primary: #4B4998;
  --lightneutral: white;
  --darkneutral: black;
  --secondary: #FDC309;
}
@font-face {
  font-family: 'Belinda New Bold';
  src: local('Belinda New Bold'), url('fonts/Belinda\ New\ Bold.otf') format('opentype');
}
@font-face{
  font-family: 'Tusker Grotesk';
  src: local('Tusker Grotesk'), url('./fonts/tusker-grotesk-5500-medium.ttf') format('truetype');
}
@font-face {
  font-family: 'The Seasons Bold Italic';
  src: local('The Seasons Bold Italic'), url('./fonts/The\ Seasons\ Bold\ Italic.otf') format('opentype');
}
@font-face {
  font-family: 'Chewy Pro';
  src: local('Chewy Pro'), url('./fonts/ChewyPro.otf') format('opentype');
}
@font-face{
  font-family: 'Sottel';
  src: local('Sottel'), url('./fonts/Sottel.ttf') format('truetype');
}
.style-font-5{ font-family: 'Sottel', sans-serif; }
.style-font-3{
  font-family: 'The Seasons Bold Italic', sans-serif;
}
.style-font-4{ font-family: 'Chewy Pro', sans-serif; }
.style-font{ font-family: 'Belinda New Bold', sans-serif;}
.style-font-2{ font-family: 'Tusker Grotesk', sans-serif; }
.link{ color: var(--secondary); }
.link2{ color: var(--darkneutral); text-decoration: none;}
.link3{ color: var(--primary);}
.link3{ color: var(--primary);}
.link4{ color: var(--lightneutral); }
.link2:hover, .link3:hover, .link4:hover{ color: var(--secondary)}
.datepicker{
  width: 100%;
  font-size: 13px;
}
.in-datepicker{
  background-color: transparent;
  outline: none;
  border: none;
  padding-left: 25px;
  box-sizing: border-box;
  height: 35px;
}
.datepicker-2{ background-color: #ece3ff; width: 100px;}
.datepicker-3{ background-color: transparent; }
input.datepicker{ height: 49px; border-radius: 3px; border: none; padding-left: 15px; box-sizing: border-box}
input.datepicker:focus{ outline: none; }
.react-datepicker__year-dropdown-container {margin: 12px 0;}
.react-datepicker__year-dropdown .react-datepicker__year-option{
  height: 30px;
}
.MuiPaper-root::before, .MuiPaper-loginfo::before, .MuiPaper-big-container::before,
.MuiPaper-root.MuiPaper-loginfo.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-mgboyq::before,
.MuiPaper-root.MuiPaper-big-container.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1rkyvf9::before,
.MuiPaper-root.MuiPaper-big-container.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1ip3wk-MuiPaper-root-MuiAccordion-root::before,
.MuiPaper-root.MuiPaper-loginfo.MuiPaper-rounded.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-d7tjfc-MuiPaper-root-MuiAccordion-root::before{
  background-color: var(--primary);
  border: 1px solid  var(--primary);
}
.hoverprimary:hover {
  background-color: #484692;
}
.in-input{
  background-color: transparent;
  height: 50px;
  outline: none;
  border: none;
  width: calc(100% - 50px);
}
.in-input-light{ height: 35px; outline: none; border: none;}
.in-input-light-triangle{ border-top: 18px solid transparent; border-bottom: 18px solid transparent; }
.in-input-icon{ height: 50px; width: 50px; }
.in-input-white, .in-input-white::placeholder{ color: var(--lightneutral); }
::-webkit-scrollbar{ width: 1rem  }
::-webkit-scrollbar-button{ height: 0; }
::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background-color: var(--primary);
}
::placeholder{ font-size: 16px; }
.testimonial{
  position: relative;
  overflow: hidden;
}
.testimonial::before{
  position: absolute;
  content: "";
  background-color: var(--secondary);
  width: 100%;
  height: 300%;
  top: 100%;
  left: 0;
  transition: .5s;
}
.slick-list {margin: 0 -7px;}
.slick-slide>div {padding: 0 7px;}
.testimonial:hover::before{ top: 0%; transition: .5s; }
.popup{
  background-color: var(--lightneutral);
  position: fixed;
  box-shadow: 0px 0px 25px -10px var(--darkneutral);
  padding: 20px;
  z-index: 3;
  border-radius: 20px;
  left: 50%;
  transition: bottom 3s;
  width: 50%;
}
.mail-validate{
  bottom: 50%;
}
.password-forgot{
  bottom: 40%;
}
.cookie{
  bottom: 10%;
}
.close-popup{
  bottom: -100%
}
.pre-action-button-container{ transform: translateY(250%); opacity: 0; }
.action-button-container{ animation: show-button .7s 1 ease-in; }
.action-button::before{
  content: "";
  background-color: var(--lightneutral);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  transform: scale(0);
  transition: all .35s;
}
.nav-button:hover *{
  color: var(--secondary);
  background-color: transparent;
}
.action-button:hover::before{
  transform: scale(1000);
  transition: all 1.5s;
}
@keyframes show-button {
  from{ transform: translateY(50%); opacity: 0; }
  to { transform: rotate(0%); opacity: 1; }
}

.title-typo:hover .title-typo-change{ color: var(--lightneutral)}
.title-typo:hover .title-typo-change-light{ color: var(--secondary)}
.title-typo:hover .title-typo-change-light-2{ color: var(--primary)}
.slide-hand{ animation: slide-hand 1s infinite ease-in-out }
@keyframes slide-hand {
  0%,100%{ transform: translate(0%, 0%) } 
  50%{transform: translate(4%, 4%)}
}
.slideXY{ animation: slide 2.5s infinite ease-in-out }
@keyframes slide {
  0%,100%{ transform: translate(-20%, -2.5%) scale(.6) } 
  50%{transform: translate(2.5%, 2.5%) scale(.6);}
}
.slideXY2 { animation: slide-big-scale 2.5s infinite ease-in-out  }
@keyframes slide-big-scale {
  0%, 100%{ transform: translate(0%, 0%) }
  50%{ transform: translate(25%, 65%) }
}
.job-card{
  transition: .5s;
  overflow: hidden;
  --shown-height: 0px;
  height: var(--shown-height);
  transition: all .5s;
}
.job-card-container:hover .job-card{
  overflow: visible;
  transition-delay: overflow .5s;
}

.wrap-content, .w-c-large, .wrap-content-mobile {
  display: flex;
  flex-direction: row;
}
.wrap-content-mobile{ gap: 15px }
.gap-change{ gap: 25px; padding: 70px 0;}
.displayed-menu{ display: flex; }
.dropdown-menu{ display: none; }
.wrap-content .wrap-img-container{ max-width: 20%; }
.head{ height: 175px; }
.height-max-adjust{ min-height: calc(100vh - 175px); }

.footer-first{ width: 265px; }
.footer-second{ width: 175px; }
.footer-third{ width: 250px; }
.footer-last{width: 475px;}
.img-bg{
  height: 70px;
  width: 70px;
}
.text-bg{ height: 70px; outline: none; border: none; width: 350px}

.test-form{ width: 50%; }
.waves-display{ visibility: visible; }
.carousel-container{ width: 75%;}
.spacing-stack{ transform: translateX(20px) }
.statcard-container{ width: 230px; }
.text-replace{ width: 45%; transform: translateY(-50%)}
.text-replace-2, .text-replace-3{ top: 50%; transform: translateY(-50%);}
.pp-stack{ padding-bottom: 100px; position: static;}
.co-int-stack{ padding: 100px 0; min-width: 400px;}
.width-resize{ width: 65%;}
.imginput-img{
  height: 50px;
  width: 50px;
}
.cond-rendering{ display: flex;}
.cond-rendering-inv{ display: none;}
.hand-pointer-img{
  right: -20px;
  width: 65px ;
  height: 65px;
}
.plr-resize{ padding: 45px 50px; }
.card-resize{ width: 200px; height: 300px;}
.card-img-resize{height:125px; width:125px}
.card-img-resize-2{height:75px; width:75px}
.card-button-resize{width: 125px;}

.header-opt{ gap: 20px;}
.bell {
  height: 25px;
  width: 25px;
  transform: translate(-20px, -2px);
  position: absolute ;
}
.profil {
  height: 50px;
  width: 50px;
  cursor: pointer; 
  position: absolute;
}

.menu-box { 
  width: 500px;
  height: 400px;
}
.menubox-img {
  height: 40px
}

.menubox-logout {
  padding: 10px 0px 10px 35px
}

.password-input { width: 500px }
.width-resize-tlint{ width: 70%; }
.width-resize-tlint-typo{ width: 65%; }
.padding-resize-tlint{ padding: 0 35px; }
.min-height-resize-tlint{ min-height: 1000px; }
#search-input{ width: 300px; height: 20px;}
.cb{ width: 50px; height: 50px; }
@media (max-width: 1100px) {
  .w-c-large{ flex-direction: column; }
  .dropdown-menu{ display: block; }
  .displayed-menu{ display: none;}
  .cookie, .close-popup, .mail-validate{ width:60% }
  .test-form{ width: 55%; }
  .carousel-container{ width: 60%;}
  .width-resize-tlint{ width: 80%; }
  .width-resize-tlint-typo{ width: 75%; }

  .bell {
    height: 23px;
    width: 23px;
    transform: translate(-17px, -2px)
  }
  .profil {
    height: 48px;
    width: 48px;
  }
  .password-input { width: 450px }
  .img-bg{
    height: 65px;
    width: 65px;
  }
  .text-bg{ 
    height: 65px;
    width: 350px
  }

}
@media (max-width: 900px) {
  .cookie, .close-popup, .mail-validate{ width:70% }
  .test-form{ width: 65%; }
  .text-replace-2, .text-replace-3{ top: 40%; transform: none;}
  ::placeholder{ font-size: 13px; }
  .width-resize{ width: 75%; }
  .cond-rendering{ display:none;}
  .cond-rendering-inv{ display:flex;}
  .card-img-resize-2{height:50px; width:50px}
  .width-resize-tlint{ width: 85%; }
  .width-resize-tlint-typo{ width: 80%; }
  .header-opt{ gap: 18px;}
  #search-input{ width: 250px; height: 18px;}
  .cb{ width: 45px; height: 45px; }
  .img-bg{
    height: 62px;
    width: 62px;
  }
  .text-bg{ 
    height: 62px;
    width: 300px
  }
  .min-height-resize-tlint{ min-height: 850px; }
}
.left-content{display: flex; }

@media (max-width: 850px) {
  .left-content { display: none;}
  .right-content { width: 95%; }
  .password-input { width: 500px }
}
@media (max-width: 700px) {
  .wrap-content {
    flex-direction: column;
    align-items: center;
  }
  .width-resize-tlint{ width: 95%; }
  .width-resize-tlint-typo{ width: 92%; }
  .wrap-content>*:not(.card-resize, .browser-button){
    min-width: 90%;
  }
  .width-resize{ width: 80%;}
  .no-exp-stack{ width: 50%; }
  .testimonial-img{ width: 100%; }
  .co-int-stack{ padding: 0; }
  .spacing-stack{ transform: none }
  .test-form{ width: 80%; }
  .wrap-content .wrap-img-container{ max-width: 100%; }
  .gap-change{ gap: 65px; padding: 70px 0;}
  .cookie, .close-popup, .close-mail-validate, .mail-validate{ width:85% }
  .head{ height: 120px; }
  .height-max-adjust{ min-height: calc(100vh - 120px); }
  .text-replace-2, .text-replace-3{ top: 220px; transform: none;}
  .footer-s{ width: 95%; }
  .carousel-container{ width: 75%;}
  .plr-resize{ padding: 45px 40px; }
  .header-opt{ gap: 16px;}
  .bell {
    height: 21px;
    width: 21px;
    transform: translate(-16px, -2px)
  }
  .profil {
    height: 45px;
    width: 45px;
  }
  .password-input { width: 450px }
}
@media (max-width: 600px){
  .card-img-resize-2{height:35px; width:35px}
  #search-input{ width: 200px; height: 16px;}
  .cb{ width: 40px; height: 40px; }
  .password-input { width: 400px }
  .img-bg {
    height: 40px;
    width: 40px;
  }
  .min-height-resize-tlint{ min-height: 600px; }
  .text-bg { 
    height: 47px;
  }

  .menubox-img {
    height: 30px
  }
}
@media (max-width: 500px) {
  .waves-display{ visibility: hidden; }
  .pp-stack{ padding-bottom: 0; position: relative;}
  .carousel-container{ width: 75%;}
  .text-replace{ width: 65%;}
  .text-replace-2{ top: 130px; transform: none;}
  .text-replace-3{ top: 50%; transform: translateY(-100%);}
  .width-resize{ width: 85%;}
  .imginput-img, .in-input-icon{
    height: 40px;
    width: 40px;
  }
  .plr-resize{ padding: 45px 30px; }
  .in-input{ height: 40px; }
  .in-input-light{ height: 25px; }
  .in-input-light-triangle{ border-top: 13px solid transparent; border-bottom: 13px solid transparent; }
  
  .in-datepicker{  height: 25px;}
  ::placeholder{ font-size: 10px; }
  .header-opt{ gap: 13px;}
  .bell {
    height: 20px;
    width: 20px;
    transform: translate(-15px, -2px)
  }
  .profil {
    height: 42px;
    width: 42px;
  }
  .password-input { width: 350px }

  .text-bg { 
    height: 40px;
    width: 900px;
  }

}

@media (max-width: 450px){
  .password-input { width: 300px }
}

@media (max-width: 400px){
  .head{ height: 80px; }
  .height-max-adjust{ min-height: calc(100vh - 80px); }
  .text-replace-2{ top: 100px; transform: none;}
  .co-int-stack{ max-width: 90%; }
  .gap-change{ padding: 70px 0; }
  .test-form{ width: 90%; }
  .carousel-container{ width: 80%;}
  .statcard-container{ width: 100%; }
  .text-replace{ width: 85%;}
  .padding-resize-tlint{ padding: 0 15px; }
  .wrap-content-mobile {
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }
  #search-input{ width: 80px; height: 15px;}
  .cb{ width: 20px; height: 20px; }
  .plr-resize{ padding: 30px 15px; }
  .width-resize{ width: 95%}
  .imginput-img, .in-input-icon{
    height: 25px;
    width: 25px;
  }
  .img-bg {
    height: 30px;
    width: 30px;
  }
  .text-bg{ height: 30px;}
  .in-input{ height: 35px; }
  .hand-pointer-img{
    right: 0;
    width: 40px ;
    height: 40px;
  }
  .header-opt{ gap: 2px;}
  .bell {
    height: 18px;
    width: 18px;
    transform: translate(-13px, -2px)
  }
  .profil {
    height: 40px;
    width: 40px;
  }
  .menu-box { 
    width: 480px;
  }
  .password-input { width: 250px }

}
@media (max-width: 300px){
  .head{ height: 45px; }
  .card-resize{ width: 150px; height: 250px;}
  .card-img-resize{height:75px; width:75px}
  .card-button-resize{width: 75px;}
  .height-max-adjust{ min-height: calc(100vh - 45px); }
  .text-replace-2, .text-replace-3{ top: 60px; transform: scale(.85);}
  .pp-stack{position: static;}
  .footer-s{ width: 85%; }
  .carousel-container{ width: 75%; }
  .text-replace{ width: 100%; transform: scale(.75) translateY(-75%);}
  .width-resize{ width: 100%}
  .plr-resize{ padding: 20px 0px; }
  .padding-resize-tlint{ padding: 0 5px; }

  .img-bg {
    height: 20px;
    width: 20px;
  }
  .text-bg{ height: 20px;}
  .header-opt{ gap: 2px;}
  .bell {
    height: 16px;
    width: 16px;
    transform: translate(-11px, 3px)
  }
  .profil {
    height: 40px;
    width: 40px;
  }
  .menu-box { 
    width: 300px;
  }

  .password-input { width: 200px }

  .menubox-img {
    height: 25px
  }

}

@media (max-width: 250px){
  .header-opt{ gap: 0px;}
  .bell {
    height: 13px;
    width: 13px;
    transform: translate(-9px, 3px)
  }
  .profil {
    height: 35px;
    width: 35px;
  }
  .menu-box { 
    width: 100%;
  }

  .password-input { width: 180px }

  .menubox-logout {
    padding: 10px 0px 10px 15px
  }
}

@media (max-width: 230px) {
  .menubox-logout {
    padding: 10px 0px 10px 6px
  }
}

.action-blogcard img{ transition: .5s; }
.action-blogcard:hover img{
  transform: rotate(3deg) scale(1.05);
  opacity: .80;
}
.usericon img{ transition: .5s; }
.usericon:hover img:not(.bell){
  transform: scale(1.05) ;
}


.sliding-text {
  --width: 0;
  --anim-duration: 7s;
  animation: slide-text var(--anim-duration) ease-in-out infinite;
}
@keyframes slide-text{
  0%, 5%{ left: 0;}
  45%, 55%{ left: calc(100% - var(--width));}
  95%, 100%{ left: 0;}
}
.display-block{ display: block; }
.display-none{ display: none; }
.pdf-preview canvas{ height: 50%; width: 100px; }